<template>
  <div class="hall">
    <div id="loading" v-if="loading">
      <div class="load-box">
        <img src="@/assets/img/hall/loading.gif" alt class="load" />
        <p>数据加载中，请稍后...</p>
      </div>
    </div>

    <!-- 皮肤选择 -->
    <themeChoice @choice="choiceType($event)"></themeChoice>

    <!-- 左侧搜索栏 -->
    <search :CompMag="CompMag" :HallList="HallList" @addhistory="addhistory" @drawCom="drawMap" @drawNoCom="drawNoCom" @isLogin="isLogin" @deteleCom="deteleCom"></search>
    <!-- <searchTool
      @drawCom="drawMap"
      :CompMag="CompMag"
      @tradeClick="tradeClick"
      @deteleCom="deteleCom"
      @isLogin="isLogin"
      @addhistory="addhistory"
      @openBigImg="openBigImg"
      @drawNoCom="drawNoCom"
      :selectHall="selectHall"
      :HallList="HallList"
    ></searchTool> -->

    <!-- 右侧搜索列表 -->
    <!-- <hallTool></hallTool> -->

    <!-- 工具栏 -->
    <div class="tool">
      <div id="bar_rotate">
        <span title="上移" id="top" @click="tranfrom('top')">
          <i></i>
        </span>
        <span title="右移" id="right" @click="tranfrom('right')">
          <i></i>
        </span>
        <span title="下移" id="bottom" @click="tranfrom('bottom')">
          <i></i>
        </span>
        <span title="左移" id="left" @click="tranfrom('left')">
          <i></i>
        </span>
      </div>
      <div class="tool_bar">
        <div id="bar_jia" title="放大" style="font-size: 30px;" @click="rotate('add')">+</div>
        <div id="bar_init" title="最佳" @click="rotate('init')">
          <i class="icon iconfont icon-fitScreen"></i>
        </div>
        <div id="bar_jian" title="缩小" style="height:36px;" @click="rotate('cut')">
          <i class="icon iconfont icon-suoxiao"></i>
        </div>
      </div>
    </div>

    <!-- 全景图 -->
    <div id="Panorama-min" @click="BigImg" v-if="exhibitionInfo && exhibitionInfo.hallPanorama">
      <img :src="exhibitionInfo.hallPanorama" alt />
      <span>全景图</span>
    </div>

    <!--全景图 -->
    <Modal v-model="PanoramaModal" class-name="vertical-center-modal mobile myModel" width="800" :mask-closable="false" footer-hide>
      <div>
        <img :src="exhibitionInfo.hallPanorama" style="width:90%;height:500px;display:block;margin:auto;" />
      </div>
    </Modal>

    <!-- 登录 -->
    <!-- <hallLogin ref="hallLogin" v-if="!login"></hallLogin> -->

    <!-- 我的信息 -->
    <!-- <hallMyInfo v-if="login" @drawCom="drawMap"></hallMyInfo> -->

    <!-- <div class="footer">
      <div class="footer-logo">
        <img src="../../assets/img/hall/zsx-logo.png" alt />
      </div>
      <div class="footer-msg">
        Copyright © {{year}} zhanshangxiu.com All Rights Reserved.
        <a
          href="http://www.beian.miit.gov.cn/"
          target="_blank"
          class="a-number"
        >沪ICP备09006318号</a>&nbsp;
        <a href="javascript:;" @click="openTerms">服务条款</a> |
        <a href="javascript:;" @click="boothClaimClick">展位认领</a> |
        <a href="javascript:;" @click="opinionClick">意见建议</a> |
        <a href="javascript:;" @click="connectFlag = true;">联系我们</a> |
        <a href="javascript:;" @click="mobileFlag = true;">移动端</a>
      </div>
    </div> -->
    <!-- 展位认领弹窗 -->
    <!-- <boothClaim :boothClaimFlag="boothClaimFlag" :HallList="HallList" @closeboothClaim="boothClaimFlag=false;" /> -->
    <!-- 意见建议弹窗 -->
    <!-- <opinion :opinionFlag="opinionFlag" @closeOpinion="opinionFlag=false;"></opinion> -->
    <!-- 联系我们弹窗 -->
    <!-- <connect :connectFlag="connectFlag" @closeConnect="connectFlag=false;" /> -->
    <!-- 移动端弹窗 -->
    <!-- <mobileCode :mobileFlag="mobileFlag" @closeMobile="mobileFlag=false;" /> -->
    <!-- 展位图DOM -->
    <div class="main" id="Map" :style="background"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

// import boothClaim from "./components/halls-boothclaim/halls-boothclaim.vue";
// import hallTool from "./components/hall-tool.vue"; // 右侧工具栏 （列表视图/常用地点/收藏）
// import hallLogin from "./components/halls-login/halls-login.vue"; // 未登录
// import hallMyInfo from "./components/hall-myinfo.vue"; // 登录信息 我的
// import opinion from "./components/halls-opinion/halls-opinion.vue";
// import connect from "./components/halls-connect/halls-connect.vue";
// import mobileCode from "./components/halls-mobile/halls-mobile.vue";
import themeChoice from "./components/theme-choice.vue";

import searchTool from "./components/search-tool.vue";
import search from "./components/search.vue";

import G6 from "@antv/g6";

export default {
  data () {
    return {
      HallList: [],
      floor: null, // 展位图图元数据；
      floorHalls: [], // 存储绘制展馆底图的列表数据
      loading: true, // 加载显示
      dataModel: null,
      g3d: null,
      MyIcon: null, // 绘制icon;
      Bind: false,
      comData: null,
      isSearchOpen: false, // 搜索弹框；
      is2D: true,
      lastFocusData2: null, // 选中的图元（搜索）
      selectList: null, // 符合条件的列表数据；
      showHallList: false, // 是否显示展馆列表
      showProList: false, // 是否显示展品列表
      searchVal: null, // 搜索的值
      iptVal: null, // 输入框显示的值
      storageArr: [], // 保存历史记录
      CompMag: null, // 公司信息
      showPd1: false, // 推荐品牌下拉框
      showPd2: false, // 公司展位列表下拉框
      showPd3: false, // 公司详细信息下拉框
      showIpt: true, // 搜素框切换；
      showiptClose: false, // 搜索内容清除icon
      boothClaimFlag: false, // 展位认领
      opinionFlag: false, // 意见建议
      connectFlag: false, // 联系我们
      mobileFlag: false, // 移动端
      showActList: false, // 显示活动列表
      findTabType: "zs", // 默认选择展商
      showrank: true, // 显示展商列表
      background: "background:#f2faff", // 默认展位图背景色
      quickFinder: null, // 快速查询
      exConfig: null, // 展会基本配置
      isGoodMap: true,
      isMouse: false, // 鼠标是否滚动
      isSetHistory: false,
      boothMap: null, // 展商展位图全景图

      render: null,
      selectHall: null, // 选中的图元(展位)
      selectNode: null,
      g2d: null,
      initColor: "#addfff",
      hoverColor: "#3498db",
      selectColor: "#fe781d",
      PanoramaModal: false,
      openHall: false,
      isBoothClaim: false, // 展后2个月关闭
    };
  },
  components: {
    // boothClaim,
    // opinion,
    // hallTool,
    // hallMyInfo,
    // connect,
    // mobileCode,
    // hallLogin,
    searchTool,
    search,
    themeChoice,
  },
  async created () {
    // if (this.exhibitionId) {
    //   await this.getExConfig(this.exhibitionId);
    //   await this.getExbibition(this.exhibitionId);
    //   await this.$service.getHost(this, this.exhibitionId);
    //   this.tipModal();
    // }
    // let that = this;
    // window.addEventListener("storage", function(e) {
    //   if (e.key === "token") {
    //     if (e.newValue) {
    //       that.setTokenData(e.newValue);
    //       that.setToken(e.newValue);
    //       that.getUser();
    //     } else {
    //       window.location.reload();
    //     }
    //   }
    // });
    // this.setSection();
    this.init();
  },
  computed: {
    ...mapGetters({
      login: "getUser", // 是否登录
      entranceInfo1: "getCustomerInfo",

      exhibitionInfo: "getExhibitionInfo",
      userInfo: "getUser",
    }),
    exhibitionId () {
      return this.$route.params.id;
    },
    header () {
      return this.$store.state.header2;
    },
    year () {
      var date = new Date();
      return date.getFullYear();
    },
    Exconfig () {
      const exconfig = localStorage.getItem("Exconfig");
      return exconfig ? JSON.parse(exconfig) : null;
    },
    entranceInfo () {
      return JSON.parse(localStorage.getItem("exConfig"));
    },
    cWidth () {
      return document.body.clientWidth;
    },
    cHeight () {
      return document.body.clientHeight;
    },
  },
  methods: {
    ...mapActions({
      getExbibition: "init/getExbibition",
      getUser: "hall/getUser",
      graphqlPostByTu: "graphqlPostByTu",
      unverifiedDialog: "unverifiedDialog",
    }),

    ...mapMutations({
      setTokenData: "hall/setTokenData",
      setToken: "setToken",
    }),

    setSection () {
      let cur = new Date().getTime();
      let end2 = new Date(this.entranceInfo1.EndDate).getTime() + 1000 * 60 * 60 * 24 * 60;
      if (cur < end2) {
        // 马上认领
        this.isBoothClaim = true;
      }
    },

    tipModal () {
      // 展会未开通展位视图
      if (this.exConfig) {
        this.openHall = this.exConfig.BoothMap;

        if (this.exConfig.BoothMap) {
          this.$nextTick(() => {
            this.init();
          });
          return;
        }
      }
      let that = this;
      this.$Modal.warning({
        title: "提示",
        content: "该展会暂未开通展位视图",
        onOk: function () {
          that.$router.push({ path: "/ranking/" + that.exhibitionId });
        },
      });
    },
    tipMpaModal () {
      // 没有展位信息时提示
      let that = this;
      this.$Modal.warning({
        title: "提示",
        content: "该展会暂未开放展位视图",
        onOk: function () {
          that.$router.push({ path: "/ranking/" + that.exhibitionId });
        },
      });
    },
    //  初始化展馆数据；
    async initHallData () {
      let HallList = null;
      let HallListExhibitionId = sessionStorage.getItem('HallListExhibitionId') || null;
      if (HallListExhibitionId && HallListExhibitionId === this.exhibitionId) {
        HallList = sessionStorage.getItem('HallList') ? JSON.parse(sessionStorage.getItem('HallList')) : null;
      }
      console.log(HallList, 'HallListHallListHallList')
      if (HallList) {
        return new Promise((resolve, reject) => {
          this.HallList = HallList;
          // if (!res.data.Data || !res.data.Data.length) {
          //   this.tipMpaModal();
          // }
          resolve(false);
        });
      } else {
        let query = `#graphql
          query {
            map {
              maps(exhibitionId: "${this.exhibitionId}", isShow: tru) {
                backImage
                name
                nameEn
                shortName
                id
                width
                height
                offset
                shapes {
                  shapeIndex
                  angle
                  shapeType
                  borderColor
                  fillColor
                  text
                  nodes
                  boothCode
                  openingDirection
                  boothArea
                  height
                  width
                  length
                  id
                  businessData {
                    dealId
                    rowState
                    exhibitor {
                      joinId
                      name
                      nameEn
                      shortName
                    }
                  }
                }
              }
            }
          }`;
        let opt = {
          query: query,
        };
        let res = await this.graphqlPostByTu(opt);
        return new Promise((resolve, reject) => {
          if (res.data.map.maps) {
            this.HallList = res.data.map.maps;
            console.log(this.HallList, "this.HallList");
            resolve(false);
          }
        });
      }
    },
    async init () {
      this.loading = await this.initHallData();
      this.initMyColor();
      if (!this.loading) {
        this.creatCanvas();
      }
    },

    // 初始化颜色
    initMyColor () {
      let All = localStorage.getItem("choiceColor") ? JSON.parse(localStorage.getItem("choiceColor")) : null;
      if (All) {
        this.background = `background:${All.color}`;
        this.initColor = All.boxColor;
        this.hoverColor = All.tabColor;
      }
    },

    creatCanvas () {
      // 创建 G6 图实例
      let AllW = 0;
      let MaxH = 0;
      this.HallList.map((c) => {
        AllW = AllW + c.width;
        if (c.height > MaxH) {
          MaxH = c.height;
        }
      });
      console.log(MaxH, "最大高度");
      console.log(AllW, "总宽度");
      var Wscale = this.cWidth / AllW < 4 ? this.cWidth / AllW : 4; // 宽度比
      console.log(Wscale, "宽度比例");
      var Hscale = (this.cHeight - MaxH * Wscale) / 2;
      var Wcenter = (this.cWidth - AllW * Wscale) / 2;
      console.log(Wcenter, "宽度居中比例"); 
      console.log(Hscale, "高度比例");

      // ShapeType 绘制的类型
      // 矩形形状：0
      // 线段：1
      // 文本：2
      // L形状：3
      // 矩形展位：4
      // L形展位：5
      // 辅助线：7

      // 循环绘制展位
      this.HallList.map((c, j) => {
        // c.offset = 0;
        c.shapes.map((v, i) => {
          if (JSON.parse(v.nodes).length < 4) {
            return true;
          } else {
            var points = [];
            let Nodes = JSON.parse(v.nodes);
            Nodes.map((v) => {
              points.push(
                [(v.X + c.offset) * Wscale + Wcenter, v.Y * Wscale + Hscale] //x轴减去的值和展馆底图位置图一致；
              );
            });

            let minWidth = Nodes[2].X - Nodes[0].X > 0 ? Nodes[0].X : Nodes[2].X; // 最小的坐标
            var bWidth = (Nodes[2].X - Nodes[0].X) * Wscale; //展位宽度
            bWidth = Math.abs(bWidth);
            var bHeight = (Nodes[2].Y - Nodes[0].Y) * Wscale; //展位宽度
            // 字体
            var hWidth = v.width;
            var fSize = 0;
            let hS = 0;
            let ws = Wscale;

            if (hWidth < 3.3) {
              // 一个标展的宽度
              if (v.boothCode && v.boothCode.length > 4) {
                ws = Wscale - (v.boothCode.length - 4) * 0.05;
              }
              hS = 1;
            }
            if (hWidth >= 3.3) {``
              // 2个标展的宽度
              if (v.boothCode && v.boothCode.length > 4) {
                ws = Wscale - (v.boothCode.length - 4) * 0.07;
              }
              hS = 1.2 + (hWidth / 3) * 0.1;
            }
            fSize = v.boothCode ? ((3 * ws) / 2) * hS : 4;

            // console.log(v.boothCode, v,hWidth,fSize,'字体');

            // (字体的计算，字体大小6像素；文字截取以标展3*3为单位，以一个展位号为4位数为基本，算出截取时一个文字的大小)
            // var fSize =  3* Wscale / 4 ;
            var text = this.fittingString(v.boothCode, hWidth, fSize); // 展位字体 (文字内容，最大宽度，字体大小)

            if (v.shapeType == "TEXT_SHAPE") {
              // 文本
              console.log("绘制文本");
              if (v.text) {
                v.text = v.text.indexOf("[") !== -1 ? JSON.parse(v.text) : v.text;
              } else {
                v.text = null;
              }
              if (v.text) {
                G6.registerNode(
                  v.id,
                  {
                    drawShape: function drawShape (cfg, group) {
                      const img = group.addShape("text", {
                        attrs: {
                          x: (minWidth + c.offset) * Wscale + Wcenter + bWidth / 2, // 居中
                          y: Nodes[0].Y * Wscale + Hscale + bHeight / 2,
                          textAlign: "center",
                          textBaseline: "center",
                          text: v.text[0].text,
                          fill: "#333",
                          fontSize: v.text[0].fontSize / 3,
                        },
                      });
                      return img;
                    },
                  },
                  "single-shape"
                );
              }
            } else {
              G6.registerNode(
                v.id,
                {
                  // 绘制自定义节点
                  drawShape: (cfg, group) => {
                    const rect = group.addShape("polygon", {
                      attrs: {
                        points: points,
                        fill: this.initColor, // 初始展位颜色
                        lineWidth: 0.2,
                        stroke: "#000", // 边框色（滑过色）
                        cursor: "pointer",
                      },
                    });
                    group.addShape("text", {
                      // attrs: style
                      attrs: {
                        x: (minWidth + c.offset) * Wscale + Wcenter + bWidth / 2, // 居中
                        y: Nodes[0].Y * Wscale + Hscale + bHeight / 2,
                        textAlign: "center",
                        textBaseline: "middle",
                        text: v.boothCode,
                        fill: "#333",
                        fontSize: fSize,
                        cursor: "pointer",
                        fontFamily: "微软雅黑",
                        W: bWidth,
                      },
                    });
                    return rect;
                  },
                  // 响应状态变化
                  setState: (name, value, item) => {
                    const group = item.getContainer();
                    const shape = group.get("children")[0]; // 顺序根据 draw 时确定 绘制的多边形
                    const text = group.get("children")[1]; // 顺序根据 draw 时确定 绘制的多边形
                    if (name === "selected") {
                      if (value) {
                        shape.attr("fill", this.selectColor);
                        text.attr("fill", "#fff");
                      } else {
                        shape.attr("fill", this.initColor);
                        text.attr("fill", "#333");
                      }
                    }
                    if (name === "running") {
                      if (value) {
                        shape.attr("fill", this.hoverColor);
                        if (this.hoverColor == "#fff") {
                          text.attr("fill", "#333");
                        } else {
                          text.attr("fill", "#fff");
                        }
                      } else {
                        shape.attr("fill", this.initColor);
                        text.attr("fill", "#333");
                      }
                    }
                    if (name === "active") {
                      const style = {
                        fill: this.initColor,
                        // stroke: this.hoverColor,
                      };
                      if (value) {
                        shape.attr(style);
                      } else {
                        shape.attr(style);
                      }
                    }
                  },
                },
                "single-shape"
              ); //single-shape 是这些内置节点图形的基类，也可以基于它进行扩展。
            }
          }
        });
      });

      let node = [];
      let Hall = [];
      this.HallList.map((c, j) => {
        // 循环绘制展馆 
        G6.registerNode(
          c.name,
          {
            drawShape: function drawShape (cfg, group) {
              const img = group.addShape("image", {
                attrs: {
                  x: (0 + c.offset) * Wscale + Wcenter,
                  y: 0 + Hscale,
                  width: c.width * Wscale,
                  height: c.height * Wscale,
                  img: c.backImage,
                  // cursor: 'pointer'
                },
              });
              group.addShape("text", {
                attrs: {
                  x: (0 + c.offset) * Wscale + Wcenter + (c.width / 2) * Wscale, // 居中
                  y: 0 + Hscale - 10,
                  textAlign: "center",
                  textBaseline: "bottom",
                  text: c.shortName,
                  fill: "#333",
                  fontSize: 30,
                  fontWeight: 600,
                },
              });
              return img;
            },
          },
          "single-shape"
        );
        Hall.push({ x: 0, y: 0, shape: c.name });

        c.shapes.map((v, i) => {
          if (JSON.parse(v.nodes).length < 4) {
            return true;
          } else {
            var hallMsg = {};
            let Nodes = JSON.parse(v.nodes);
            if (v.shapeType == "TEXT_SHAPE") {
              // 判断是否为文本
              if (v.text) {
                hallMsg = {
                  x: 0,
                  y: 0,
                  shape: v.id,
                  // bWidth:bWidth,
                  // HallMsg: v,
                  w: (Nodes[0].X + c.offset) * Wscale + Wcenter, // 当前节点的左上角坐标x轴
                  h: Nodes[0].Y * Wscale + Hscale, // 当前节点的左上角坐标Y轴
                  ComId: v.businessData && v.businessData.length && v.businessData[0].exhibitor ? v.businessData[0].exhibitor.joinId : "",
                };
              }
            } else {
              hallMsg = {
                x: 0,
                y: 0,
                shape: v.id,
                // bWidth:bWidth,
                HallMsg: v,
                w: (Nodes[0].X + c.offset) * Wscale + Wcenter, // 当前节点的左上角坐标x轴
                h: Nodes[0].Y * Wscale + Hscale, // 当前节点的左上角坐标Y轴
                ComId: v.businessData && v.businessData.length && v.businessData[0].exhibitor ? v.businessData[0].exhibitor.joinId : "",
              };
            }
            node.push(hallMsg);
          }
        });
      
      });

      const data = {
        nodes: [...Hall, ...node],
      };

      // // 实例化 Grid 插件
      this.g2d = new G6.Graph({
        container: "Map", // 指定图画布的容器 id
        // 画布宽高
        width: this.cWidth,
        height: this.cHeight,
        fitView: true,
        modes: {
          default: ["drag-canvas", "zoom-canvas"],
        },
      });
      const graph = this.g2d;

      // 读取数据
      graph.data(data);
      // 渲染图
      graph.render();

      // 拖拽画布
      var x = 0;
      var y = 0;
      graph.on("node:drag", (evt) => {
        if (x != 0 && y != 0) {
          var xx = x - evt.event.x;
          var yy = y - evt.event.y;
          // console.log(xx,yy);
          graph.translate(-xx, -yy);
          this.isDrag = true;
        }
        x = evt.event.x;
        y = evt.event.y;
      });

      graph.on("mouseup", (ev) => {
        x = 0;
        y = 0;
      });

      // 鼠标滑过切换改变样式
      graph.on("node:mouseenter", (ev) => {
        const node = ev.item;
        if (this.selectNode && this.selectNode == node) {
          // 当前选中的节点
          return;
        } else {
          graph.setItemState(node, "running", true);
        }
      });

      graph.on("node:mouseleave", (ev) => {
        const node = ev.item;
        if (this.selectNode && this.selectNode == node) {
          return;
        } else {
          graph.setItemState(node, "running", false);
        }
      });

      // 切换选中改变样式
      graph.on("node:click", (ev) => {
        const node = ev.item;
        // console.log(node,'选中的节点');
        if (this.selectNode != node && node._cfg.model.HallMsg) {
          if (!this.login) {
            this.unverifiedDialog(this);
            return;
          }
          if (this.selectNode) {
            graph.setItemState(this.selectNode, "selected", false); // 初始化选中
          }
          if (node._cfg.model.HallMsg) {
            // 展位节点；
            this.selectHall = node._cfg.model.HallMsg; // 点击选中的公司
            this.selectNode = node;
            graph.setItemState(node, "selected", true); // 切换选中
            this.SelNode(node, graph);
          }
        }
      });

      window.addEventListener("resize", (e) => {
        graph.changeSize(window.innerWidth, 680);
      });
    },

    // 选中节点的操作
    SelNode (Node, graph) {
      // 选中节点后的操作
      console.log(Node, "选中的节点操作 1");
      graph.zoomTo(3, { x: Node._cfg.model.w, y: Node._cfg.model.h });
      this.handleNodeClick(Node, graph); // 选中操作canvas;
      this.clickComp(Node); // 绘制选中的公司
    },

    // 选中平移(搜索选中)
    handleNodeClick (node, graph) {
      const item = node;
      // 聚焦当前点击的节点（把节点放到视口中心）
      const matrix = item._cfg.model;
      console.log('matrix', matrix);

      const point = {
        // 当前节点的坐标
        x: matrix.w,
        y: matrix.h,
      };
      const width = graph.get("width");
      const height = graph.get("height");
      // 找到视口中心
      const viewCenter = {
        x: width / 2,
        y: height / 2,
      };
      const modelCenter = graph.getPointByCanvas(viewCenter.x, viewCenter.y);
      const viewportMatrix = graph.get("group").getMatrix();
      // 画布平移的目标位置，最终目标是graph.translate(dx, dy);
      console.log(viewportMatrix, "viewportMatrix");
      console.log(modelCenter, "modelCenter"); 
      const dx = (modelCenter.x - point.x) * viewportMatrix[0];
      const dy = (modelCenter.y - point.y) * viewportMatrix[4];
      let lastX = 0;
      let lastY = 0;
      let newX = void 0;
      let newY = void 0;
      // console.log(dx, dy, "dx", newX, newY);
      // 动画每次平移一点，直到目标位置
      graph.get("canvas").animate(
        {
          onFrame: function onFrame (ratio) {
            newX = dx * ratio;
            newY = dy * ratio;
            graph.translate(newX - lastX, newY - lastY);
            lastX = newX;
            lastY = newY;
          },
        },
        300,
        "easeCubic"
      );
    },

    // 工具栏操作平移
    tranfrom (type) {
      switch (type) {
        case "top":
          // this.g2d.translate(0, 10);
          this.g2d.get("canvas").animate(
            {
              onFrame: () => {
                this.g2d.translate(0, 5);
              },
            },
            200,
            "easeCubic"
          );
          break;
        case "right":
          this.g2d.get("canvas").animate(
            {
              onFrame: () => {
                this.g2d.translate(-5, 0);
              },
            },
            200,
            "easeCubic"
          );
          break;
        case "bottom":
          this.g2d.get("canvas").animate(
            {
              onFrame: () => {
                this.g2d.translate(0, -5);
              },
            },
            200,
            "easeCubic"
          );
          break;
        case "left":
          this.g2d.get("canvas").animate(
            {
              onFrame: () => {
                this.g2d.translate(5, 0);
              },
            },
            200,
            "easeCubic"
          );
          break;
      }
    },

    // 工具栏操作旋转，放大
    rotate (type) {
      // 找到视口中心(以视口中心经行缩放)
      var viewCenter = {
        x: this.cWidth / 2,
        y: this.cHeight / 2,
      };
      switch (type) {
        case "2D":
          break;
        case "3D":
          break;
        case "add": // 放大
          this.g2d.zoom(1.2, viewCenter);
          break;
        case "cut": // 缩小
          this.g2d.zoom(0.8, viewCenter);
          break;
        case "init": // 最佳
          this.g2d.fitView(0);
          break;
      }
    },

    // 删除选中展位的选中样式
    delSelcetHall (halls) {
      console.log(halls, "删除当前选中展位的样式");
      this.g2d.setItemState(this.selectNode, "selected", false); // 删除选中的样式
      this.selectNode = null;
    },

    // 搜索(最终的搜索条件)
    sreach () {
      var value = this.searchVal ? this.searchVal.trim() : "";
      console.log(value, "搜索的条件");
      var has = false;
      this.g2d.find("node", (node) => {
        if (node.get("model").hasOwnProperty("HallMsg") && node.get("model").HallMsg) {
          let comList = node.get("model").HallMsg.hasOwnProperty("businessData") ? node.get("model").HallMsg.businessData : []; // 防止一个展位对应多个公司
          comList.map((c) => {
            if (c.exhibitor && c.exhibitor.joinId && c.exhibitor.joinId === value) {
              // 可能一家公司有多个展位
              if (node !== this.selectNode) {
                if (this.selectNode) {
                  // console.log('删除前一个')
                  this.g2d.setItemState(this.selectNode, "selected", false);
                }
                if (node) {
                  // console.log('绘制现在')
                  this.g2d.setItemState(node, "selected", true);
                  this.SelNode(node, this.g2d);
                  //  this.handleNodeClick(node,this.g2d);
                }
                this.selectNode = node;
              }
              has = true;
            }
          });
          if (node.get("model").HallMsg.boothCode === value) {
            // 没有绑定公司的展位
            if (node !== this.selectNode) {
              if (this.selectNode) {
                this.g2d.setItemState(this.selectNode, "selected", false);
              }
              if (node) {
                this.g2d.setItemState(node, "selected", true);
                this.SelNode(node, this.g2d);
              }
              this.selectNode = node;
            }
            has = true;
          }
        }
      });
      if (!has) {
        // 没有选中
        this.$Message.warning(`未匹配到此展位号，请联系主办方客服`);
      }
    },

    //   // 显示选中的公司数据
    //   // selectComp(Com) {
    //   //   // 公司/搜索的值
    //   //   this.clickComp(Com); // 绘制选中的公司
    //   //   var comp = Com._styleMap.my; // 当前的公司；
    //   //   this.is2D = true;
    //   //   this.g3d.reset(); // 恢复初始化的eye/center;
    //   //   this.g3d.setZoom(2, false); // 动画必须为false;
    //   //   this.g3d.pan(Com._position.x, -Com._position.y, true, false); // 选中的展位偏移的位置
    //   // },

    addStore () {
      //添加历史记录(保存到本地缓存)
      let serachArr = localStorage.getItem("search_save" + this.exhibitionId) ? JSON.parse(localStorage.getItem("search_save" + this.exhibitionId)) : null;
      if (serachArr) {
        this.storageArr = serachArr;
      }
      this.storageArr.unshift({ val: this.iptVal });
      let obj = {};
      this.storageArr = this.storageArr.reduce((item, next) => {
        // 数组去重
        obj[next.val] ? "" : (obj[next.val] = true && item.push(next));
        return item;
      }, []);
      if (this.storageArr.length > 5) {
        // 保留5条历史数据；
        this.storageArr.pop();
      }
      localStorage.setItem("search_save" + this.exhibitionId, JSON.stringify(this.storageArr));
    },

    addhistory (val) {
      this.iptVal = val;
      this.addStore();
      this.isSetHistory = true;
    },

    // 全景图
    BigImg () {
      this.PanoramaModal = true;
    },

    // 显示选中公司弹框
    clickComp (Com) {
      //鼠标点击绘制公司
      var comp = Com._cfg.model.HallMsg; // 当前的公司；
      this.CompMag = comp; // 当前展位
    },

    // 删除选中的公司
    deteleCom () {
      if (this.selectNode) {
        this.delSelcetHall(this.selectNode);
        this.CompMag = null;
      }
    },

    //判断是否登录（没有登录）
    isLogin () {
      // this.$refs.hallLogin.showModal = true;
    },

    //   // 跳转到服务条款
    //   openTerms() {
    //     const { href } = this.$router.resolve({
    //       name: "terms"
    //     });
    //     window.open(href, "_blank");
    //   },

    // 点击展商排行榜绘制展位图；
    drawMap (option) {
      // 用公司的id去查展位;
      console.log(option.item, "右侧搜索选中的公司");
      // this.searchVal = item.Booth; //根据展位号去查展位图；（一个公司可能又多个展位；如果根据公司id查）
      this.searchVal = option.item.id; // 利用公司id搜索
      this.iptVal = option.searVal; // 显示的搜索条件
      this.sreach();
    },

    drawNoCom (option) {
      console.log(option.item, "搜索没有绑定公司的展位");
      this.searchVal = option.item.boothCode; // 利用展位的id搜索
      this.iptVal = option.searVal; // 显示的搜索条件
      this.sreach();
    },

    // 切换皮肤
    choiceType (e) {
      // console.log(e,'切换皮肤');
      this.background = `background:${e.color}`;
      this.initColor = e.boxColor;
      this.hoverColor = e.tabColor;
      const nodes = this.g2d.getNodes();
      // console.log(nodes)

      nodes.map((c) => {
        if (this.selectNode == c) {
          this.g2d.setItemState(c, "selected", true);
        } else {
          this.g2d.setItemState(c, "active", false); // 初始化选中
        }
      });

      nodes.map((c) => {
        if (this.selectNode == c) {
          this.g2d.setItemState(c, "selected", true);
        } else {
          this.g2d.setItemState(c, "active", true); // 初始化选中
        }
      });
    },

    //   // 点击展位认领
    //   boothClaimClick() {
    //     if (!this.login) {
    //       this.$Message.warning(`您还未登录，请先登录再进行操作`);
    //       this.$refs.hallLogin.showModal = true;
    //       return;
    //     }
    //     if(!this.isBoothClaim){
    //       this.$Message.warning("展位认领入口已关闭");
    //       return;
    //     }
    //     this.boothClaimFlag = true;
    //   },

    //   // 点击意见建议
    //   opinionClick() {
    //     if (!this.login) {
    //       this.$Message.warning(`您还未登录，请先登录再进行操作`);
    //       this.$refs.hallLogin.showModal = true;
    //       return;
    //     }
    //     this.opinionFlag = true;
    //   },

    //   // 点击贸易对接
    //   tradeClick() {
    //     if (!this.login) {
    //       this.$Message.warning(`您还未登录，请先登录再进行操作`);
    //       this.$refs.hallLogin.showModal = true;
    //       return;
    //     }
    //   },

    //   //展商详情打开展位图全景图
    //   openBigImg(obj){
    //     // console.log(obj,'123');
    //     this.boothMap = obj;
    //     setTimeout(() => {
    //       layer.open({
    //         type: 1,
    //         title: false,
    //         area: ["70%", "70%"],
    //         shadeClose: true, //点击遮罩关闭
    //         content: $("#Panorama-max2")
    //       });
    //     }, 20);
    //   },

    fittingString (str, maxWidth, fontSize) {
      var fontWidth = fontSize; //字号+边距
      maxWidth = maxWidth; // 需要根据自己项目调整
      if (str) {
        var width = this.calcStrLen(str) * fontWidth; // 文字长度
        // console.log(this.calcStrLen(str),width,maxWidth)
        if (width > maxWidth) {
          // var actualLen = Math.floor((maxWidth - 10) / fontWidth);
          var actualLen = Math.floor(maxWidth / fontWidth);
          var result = str.substring(0, actualLen) + "";
          return result;
        }
        return str;
      }
    },
    calcStrLen (str) {
      var len = 0;
      for (var i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 128) {
          len++;
        } else {
          len += 2;
        }
      }
      return len;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
html,
body {
  padding: 0px;
  margin: 0px;
}
.hall {
  position: fixed;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 998;
}
#Map {
  margin: 0px;
  padding: 0px;
  position: absolute !important;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 999;
  background: #c6e4ff;
  overflow: hidden;
  // background: url('../../assets/img/hall/bg.gif')
}
</style>
